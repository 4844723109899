<template>
    <div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item">
                <b-img :width=128 :height=128 rounded fluid :src="image"></b-img>
            </div>
            <div class="md-layout-item">
                <div class="md-layout">
                    <b-button pill class="btn" type="button" @click="addImage">
                        <md-icon class="fa fa-plus"/>
                        Add image
                    </b-button>
                    <input hidden id="uploadFileInputButton" ref="uploadFileInputButton" type="file"
                           :disabled="isSaving" @change="upload"
                           accept="image/*" class="input-file">
                </div>
                <div class="md-layout">
                    <b-button pill class="btn" type="button" @click="delImage">Delete</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {storage} from "@/main";

    export default {
        name: "UploadableImage",
        props: ['id', 'image', 'ratioX', 'ratioY', 'maxSize'],
        data: function () {
            return {
                isSaving: false,
            }
        },
        methods: {
            addImage() {
                console.log('addImage()')
                this.$refs.uploadFileInputButton.click();
            },
            delImage() {
                console.log('delImage()')
                this.$emit('deleted', this.id);
            },
            resize(settings) {
                var file = settings.file;
                var maxSize = settings.maxSize;
                var ratioX = settings.ratioX;
                var ratioY = settings.ratioY;
                var reader = new FileReader();
                var image = new Image();
                var canvas = document.createElement('canvas');
                var dataURItoBlob = function (dataURI) {
                    var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                        atob(dataURI.split(',')[1]) :
                        unescape(dataURI.split(',')[1]);
                    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
                    var max = bytes.length;
                    var ia = new Uint8Array(max);
                    for (var i = 0; i < max; i++)
                        ia[i] = bytes.charCodeAt(i);
                    return new Blob([ia], {type: mime});
                };
                var resize = function () {
                    var sx = 0; var sy = 0; var sw = 0; var sh = 0;
                    var ratio1 = image.width / image.height;
                    var ratio2 = ratioX / ratioY;
                    if (ratio1 > ratio2) {
                        var tw = image.height * ratio2;
                        sx = (image.width - tw) / 2;
                        sy = 0;
                        sw = image.width - 2*sx;
                        sh = image.height;
                    } else {
                        var th = image.width / ratio2;
                        sx = 0;
                        sy = (image.height - th) / 2;
                        sw = image.width;
                        sh = image.height - 2*sy;
                    }

                    var width = image.width;
                    var height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    // canvas.width = width;
                    // canvas.height = height;
                    canvas.width = ratioX;
                    canvas.height = ratioY;
                    // canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    canvas.getContext('2d').drawImage(image, sx, sy, sw, sh, 0, 0, ratioX, ratioY);
                    var dataUrl = canvas.toDataURL('image/jpeg');
                    return dataURItoBlob(dataUrl);
                };
                return new Promise(function (ok, no) {
                    if (!file.type.match(/image.*/)) {
                        no(new Error("Not an image"));
                        return;
                    }
                    reader.onload = function (readerEvent) {
                        image.onload = function () {
                            return ok(resize());
                        };
                        image.src = readerEvent.target.result;
                    };
                    reader.readAsDataURL(file);
                });
            },
            upload(event) {
                let that = this;

                var input = event.target;
                if (input.files && input.files[0]) {
                    var file = input.files[0];

                    this.resize({
                        file: file,
                        ratioX: this.ratioX,
                        ratioY: this.ratioY,
                        maxSize: this.maxSize
                    }).then(function (resizedImage) {

                        console.log('--- RESIZED ---');
                        console.log(resizedImage);

                        that.isSaving = true;

                        let ts = Math.floor(Date.now() / 1000).toString();

                        let uploadTask = storage.child('images').child(ts).put(resizedImage);
                        uploadTask.on('state_changed', function (snapshot) {
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        }, function (error) {
                            console.log(error);
                            that.isSaving = false;
                        }, function () {
                            console.log('finished!');
                            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                console.log('File available at', downloadURL);
                                document.getElementById("uploadFileInputButton").value = "";

                                // var downloadURL = 'https://storage.googleapis.com/aussiety.appspot.com/' + uploadTask.snapshot.metadata.fullPath;
                                that.$emit('added', that.id, downloadURL);

                                // let doc_ref = db.collection('ladies').doc(that.form.name);
                                // doc_ref.get().then(function (doc) {
                                //     if (doc.exists) {
                                //         console.log("Document data:", doc.data());
                                //         let gallery = doc.data().gallery;
                                //         gallery.push(downloadURL);
                                //         doc_ref.update({
                                //             'gallery': gallery
                                //         });
                                //         that.doLadyUpdate(that.form.name);
                                //     } else {
                                //         // doc.data() will be undefined in this case
                                //         console.log("No such document!");
                                //     }
                                // }).catch(function (error) {
                                //     console.log("Error getting document:", error);
                                // });

                            }).catch(function (reason) {
                                console.log(reason);
                            });
                            that.isSaving = false;
                        });

                    }).catch(function (err) {
                        console.error('--- ERROR ---');
                        console.error(err);
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>