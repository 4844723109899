<template>
    <div class="single-box-col-9 main-dashboard-establishments">
        <div class="md-layout headers">
            <div class="md-layout-item">
                <h2>Establishments</h2>
            </div>
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="refresh">Refresh</b-button>
            </div>
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="createEstablishment">Create new establishment
                </b-button>
            </div>
        </div>
        <div class="md-layout">

        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <b-table id="table" striped hover
                         :current-page="currentPage"
                         :per-page="perPage"
                         :filter="filter"
                         :items="items"
                         :fields="fields"
                         @row-clicked="itemClicked"
                         @filtered="onFiltered"
                >
                    <template v-slot:cell(logo)="data">
                        <b-img :width=64 :height=64 rounded fluid :src="data.item.logo"></b-img>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <b-pagination :total-rows="totalRows" v-model="currentPage" :per-page="perPage">
                </b-pagination>
            </div>
            <div class="md-layout-item" style="text-align: right;">
                <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                        style="width: 80px;"
                ></b-form-select>
            </div>
        </div>

<!--        <slideout-panel></slideout-panel>-->

    </div>
</template>

<script>
    import {bus, db} from "@/main";

    import NewEstablishment from "@/views/dialogs/NewEstablishment";

    export default {
        name: "Establishments",
        components: {},
        data: function () {
            return {
                currentPage: 1,
                perPage: 5,
                totalRows: 1,
                pageOptions: [5, 10, 15],

                filter: null,
                panel1Handle: null,
                fields: [
                    {
                        key: "logo",
                        label: "Logo",
                        sortable: false
                    },
                    {
                        key: "name",
                        label: "Name",
                        sortable: true
                    },
                    {
                        key: "category",
                        label: "Category",
                        sortable: true
                    },
                    {
                        key: "address",
                        label: "Address",
                        sortable: false
                    },
                    {
                        key: "city",
                        label: "City",
                        sortable: true
                    },
                    {
                        key: "suburb",
                        label: "Suburb",
                        sortable: true
                    },
                    {
                        key: "state",
                        label: "State",
                        sortable: true
                    },
                    {
                        key: "countryGroup",
                        label: "Country Group",
                        sortable: true
                    },
                ],
                items: []
            }
        },
        created: function () {
            let that = this;
            bus.$on("searchForUpdated", function(value) {
                that.filter = value;
            });

            this.refresh();
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            itemClicked(item) {
                console.log(item);

                let that = this;

                // this.panel1Handle = this.$showPanel({
                //     component: NewEstablishment,
                //     openOn: 'right',
                //     props: {
                //         'id': item.id
                //     }
                // });

                // this.panel1Handle.promise
                //     .then(result => {
                //         console.log(result);
                //         that.hidePanel();
                //     });

                this.$router.push({
                    path: '/sections/details',
                    query: {
                        id: item.id
                    }
                });

                bus.$emit('searchForCleared');
            },
            refresh() {
                console.log('refresh()');

                let that = this;

                this.items = [];
                this.totalRows = 1;
                this.currentPage = 1;

                console.log('----- QUERY ESTABLISHMENTS STARTED -----');
                let queryRef = db
                    .collection("benefits")
                    .doc("membership_card")
                    .collection("establishments");
                queryRef.get().then(function (querySnapshot) {
                    that.items = [];
                    querySnapshot.forEach(function (doc) {
                        console.log(doc.id, " => ", doc.data());
                        let data = doc.data();
                        let item = {
                            id: data.id,
                            logo: data.logo,
                            name: data.name,
                            category: data.category,
                            address: data.address,
                            city: data.city,
                            suburb: data.suburb,
                            state: data.state,
                            countryGroup: data.country
                        };
                        that.items.push(item);
                    });
                    that.totalRows = that.items.length;
                    console.log('----- QUERY ESTABLISHMENTS ENDED -----');
                });
            },
            createEstablishment() {
                console.log('createEstablishment()');

                let that = this;

                this.panel1Handle = this.$showPanel({
                    component: NewEstablishment,
                    openOn: 'right',
                    props: {
                        //any data you want passed to your component
                    }
                });

                this.panel1Handle.promise
                    .then(result => {
                        console.log(result);
                        that.hidePanel();
                    });
            },
            hidePanel() {
                console.log('hidePanel()');

                if (this.panel1Handle != null) {
                    this.panel1Handle.hide();
                    this.panel1Handle = null;

                    this.refresh();
                }
            }
        }
    }
</script>

<style>
    .table td {
        vertical-align: middle;
    }

    .main-dashboard-establishments {
        padding: 50px;
    }

    .main-dashboard-establishments .headers {
        margin-bottom: 10px;
    }
</style>