<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item">
                <h3>{{title}}</h3>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <md-list>
                    <md-list-item md-expand>
                        <span class="md-list-item-text">LOGO/MAIN PHOTO IMAGES</span>
                        <md-list slot="md-expand">
                            <md-list-item class="md-inset">
                                <UploadableImage :max-size="500" :ratio-x="500" :ratio-y="500" :id="establishment.name" :image="establishment.logo" @added="logoUploaded"
                                      @deleted="logoDeleted"></UploadableImage>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <UploadableImage :max-size="576" :ratio-x="576" :ratio-y="486" :id="establishment.name" :image="establishment.mainPhoto" @added="mainPhotoUploaded"
                                      @deleted="mainPhotoDeleted"></UploadableImage>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                </md-list>
                <hr>
                <md-list>
                    <md-list-item md-expand>
                        <span class="md-list-item-text">ESTABLISHMENT INFO</span>
                        <md-list slot="md-expand">
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Name</label>
                                    <md-input v-model="establishment.name"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Category</label>
                                    <md-select v-model="establishment.category">
                                        <md-option v-for="category in categories" :value="category">{{category}}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Group</label>
                                    <md-input v-model="establishment.group"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Subgroup</label>
                                    <md-input v-model="establishment.subgroup"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Address</label>
                                    <md-input v-model="establishment.address"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>State</label>
                                    <md-input v-model="establishment.state"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Postal Code</label>
                                    <md-input v-model="establishment.postalCode" type="number"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Latitude</label>
                                    <md-input v-model="establishment.lat" type="number" step="0.0001"></md-input>
                                </md-field>
                                <md-field>
                                    <label>Longitude</label>
                                    <md-input v-model="establishment.lng" type="number" step="0.0001"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>City</label>
                                    <md-input v-model="establishment.city"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Suburb</label>
                                    <md-input v-model="establishment.suburb"></md-input>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>Country</label>
                                    <md-select v-model="establishment.country">
                                        <md-option v-for="country in countries" :value="country">{{country}}</md-option>
                                    </md-select>
                                </md-field>
                            </md-list-item>
                            <md-list-item class="md-inset">
                                <md-field>
                                    <label>About Me</label>
                                    <md-textarea v-model="establishment.aboutMe" md-counter="80"></md-textarea>
                                </md-field>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                </md-list>
                <hr>
                <md-list>
                    <md-list-item md-expand>
                        <span class="md-list-item-text">BENEFITS</span>
                        <md-list slot="md-expand">
                            <md-button class="benefitsAddButton" @click="addBenefit">Add</md-button>
                            <md-list-item class="md-inset" v-for="(benefit, i) in establishment.benefits">
                                <UploadableNamedImage :max-size="429" :ratio-x="429" :ratio-y="404" :id="i" :joined="benefit.name" :image="benefit.img" @updated="benefitUpdated"
                                                 @removed="benefitRemoved"></UploadableNamedImage>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                </md-list>
            </div>
        </div>
        <hr>
        <div class="md-layout">
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="clearForm">
                    Clear
                </b-button>
            </div>
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="saveEstablishment">
                    Save
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {db} from "@/main";
    import moment from "moment";

    import UploadableImage from "@/views/items/UploadableImage"
    import UploadableNamedImage from "@/views/items/UploadableNamedImage"

    export default {
        name: "NewEstablishment",
        components: {
            UploadableImage,
            UploadableNamedImage
        },
        props: ['id'],
        data: function () {
            return {
                title: 'Create new establishment',
                editMode: false,
                categories: [
                    'beauty',
                    'experiences',
                    'restaurants',
                    'health_and_fitness',
                    'shopping',
                    'travel'
                ],
                countries: [
                    'Argentina',
                    'Australia',
                    'Bolivia',
                    'Brazil',
                    'Chile',
                    'Colombia',
                    'Ecuador',
                    'México',
                    'Panamá',
                    'Paraguay',
                    'Uruguay',
                    'Venezuela'
                ],
                establishment: {
                    aboutMe: null,
                    address: null,
                    barcode: null,
                    benefits: [],
                    category: null,
                    city: null,
                    country: null,
                    created: null,
                    enabled: true,
                    group: null,
                    id: null,
                    lat: null,
                    lng: null,
                    logo: null,
                    mainPhoto: null,
                    name: null,
                    popularity: null,
                    postalCode: null,
                    state: null,
                    subgroup: null,
                    suburb: null,
                    updated: null
                }
            }
        },
        created: function () {
            console.log(this.id);

            let that = this;

            if (this.id != null) {
                let establishmenId = this.id;
                let docRef = db
                    .collection("benefits")
                    .doc("membership_card")
                    .collection("establishments")
                    .doc(establishmenId);
                docRef.get().then(function (doc) {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());

                        that.title = 'Edit establishment';
                        that.editMode = true;

                        let data = doc.data();

                        that.establishment.aboutMe = data.about_me;
                        that.establishment.address = data.address;
                        that.establishment.barcode = data.barcode;
                        that.establishment.benefits = data.benefits;
                        that.establishment.category = data.category;
                        that.establishment.city = data.city;
                        that.establishment.country = data.country;
                        that.establishment.created = data.created;
                        that.establishment.enabled = data.enabled;
                        that.establishment.group = data.group;
                        that.establishment.id = data.id;
                        that.establishment.lat = data.lat;
                        that.establishment.lng = data.lng;
                        that.establishment.logo = data.logo;
                        that.establishment.mainPhoto = data.main_photo;
                        that.establishment.name = data.name;
                        that.establishment.popularity = data.popularity;
                        that.establishment.postalCode = data.postal_code;
                        that.establishment.state = data.state;
                        that.establishment.subgroup = data.sub_group;
                        that.establishment.suburb = data.suburb;
                        that.establishment.updated = data.updated;

                        console.log(that.establishment);
                    }
                }).catch(function (error) {
                    console.log("Error getting document:", error);
                });
            }
        },
        methods: {
            addBenefit() {
                console.log('addBenefit()');
                this.establishment.benefits.push({
                    name: null,
                    img: null
                })
            },
            benefitUpdated(position, data) {
                console.log('benefitUploaded(): ' + position);
                console.log(data);
                let joined = [data.title, data.subtitle, data.subtitle2].join('|');
                let formattedData = {
                    img: data.img,
                    name: joined
                };
                this.establishment.benefits[position] = formattedData;
            },
            benefitRemoved(position) {
                console.log('benefitDeleted(): ' + position);
                this.establishment.benefits.splice(position, 1);
            },
            mainPhotoUploaded(establishmentId, downloadURL) {
                console.log('mainPhotoUploaded()');
                console.log(establishmentId);
                console.log(downloadURL);

                let that = this;

                this.establishment.mainPhoto = downloadURL;

                let establishmentData = {
                    'updated': Date.now(),
                    'main_photo': downloadURL
                };
            },

            mainPhotoDeleted(establishmentId) {
                console.log('mainPhotoDeleted()');

                this.establishment.mainPhoto = null;

                let establishmentData = {
                    'updated': Date.now(),
                    'main_photo': null
                };
            },

            logoUploaded(establishmentId, downloadURL) {
                console.log('logoUploaded()');
                console.log(establishmentId);
                console.log(downloadURL);

                let that = this;

                this.establishment.logo = downloadURL;

                let establishmentData = {
                    'updated': Date.now(),
                    'logo': downloadURL
                };
            },

            logoDeleted(establishmentId) {
                console.log('logoDeleted()');

                this.establishment.logo = null;

                let establishmentData = {
                    'updated': Date.now(),
                    'logo': null
                };
            },

            saveEstablishment() {
                console.log('saveEstablishment()');

                let that = this;
                // let millis = parseInt(moment().format("X")) * 1000;
                let now = new Date();

                let created = this.establishment.created;
                if(created == null) {
                    created = now;
                }

                let popularity = 0;
                if(this.establishment.popularity != null)
                    popularity = this.establishment.popularity;

                let postalCode = 0;
                if(this.establishment.postalCode != null)
                    postalCode = parseInt(this.establishment.postalCode);

                let lat = 0;
                let lng = 0;
                //  TODO: this is not the correct way to implement this!!!
                if(this.establishment.lat != null)
                    lat = parseFloat(this.establishment.lat);
                if(this.establishment.lng != null)
                    lng = parseFloat(this.establishment.lng);

                let establishmenId = this.establishment.name;
                if(this.establishment.id != null)
                    establishmenId = this.establishment.id;

                let establimentData = {
                    created: created,
                    updated: now,
                    enabled: true,
                    id: establishmenId,

                    about_me: this.establishment.aboutMe,
                    address: this.establishment.address,
                    category: this.establishment.category,
                    city: this.establishment.city,
                    country: this.establishment.country,
                    group: this.establishment.group,
                    lat: lat,
                    lng: lng,
                    main_photo: this.establishment.mainPhoto,
                    name: this.establishment.name,
                    popularity: popularity,
                    postal_code: postalCode,
                    state: this.establishment.state,
                    sub_group: this.establishment.subgroup,
                    suburb: this.establishment.suburb,

                    // benefit01: this.establishment.benefit01,
                    // benefit01_img: this.establishment.benefit01_img,
                    // benefit02: this.establishment.benefit02,
                    // benefit02_img: this.establishment.benefit02_img,

                    benefits: this.establishment.benefits,

                    barcode: this.establishment.barcode,

                    logo: this.establishment.logo,
                };

                let docRef = db
                    .collection("benefits")
                    .doc("membership_card")
                    .collection("establishments")
                    .doc(establishmenId);
                docRef.get().then(function (doc) {
                    if (!that.editMode && doc.exists) {
                        console.log("Document data:", doc.data());

                        that.$toasted.show("Establishment already exists!", {
                            theme: "bubble",
                            position: "top-center",
                            duration: 2000
                        });
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");

                        docRef
                            .set(establimentData)
                            .then(function () {
                                console.log("Document successfully written!");
                                that.clearForm();

                                that.$toasted.show("Successful", {
                                    theme: "bubble",
                                    position: "top-center",
                                    duration: 2000
                                });

                                that.$emit('closePanel', {});
                            })
                            .catch(function (error) {
                                console.error("Error writing document: ", error);
                            });
                    }
                }).catch(function (error) {
                    console.log("Error getting document:", error);
                });
            },

            clearForm() {
                this.establishment = {
                    aboutMe: null,
                    address: null,
                    barcode: null,
                    // benefit01: null,
                    // benefit01_img: null,
                    // benefit02: null,
                    // benefit02_img: null,
                    benefits: [],
                    category: null,
                    city: null,
                    country: null,
                    enabled: true,
                    group: null,
                    id: null,
                    lat: null,
                    lng: null,
                    logo: null,
                    mainPhoto: null,
                    name: null,
                    popularity: null,
                    postalCode: null,
                    state: null,
                    sub_group: null,
                    suburb: null
                };
            }
        }
    }
</script>

<style scoped>
    .md-field {
        margin-right: 48px;
    }

    .benefitsAddButton {
        color: white;
        background-color: #3A63FA;
    }
</style>

<style>
    .md-select-menu {
        z-index: 110 !important;
    }
</style>